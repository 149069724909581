import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { cilQrCode } from '@coreui/icons';
import CIcon from '@coreui/icons-react'
import { useKeycloak } from "@react-keycloak/web";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Select, MenuItem, FormControl, InputLabel, Typography
} from '@mui/material';
import {
  CModal, CModalBody, CModalFooter,CModalHeader,CModalTitle
} from '@coreui/react';
import { toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { asaApiClient } from 'src/network/AsaApiclient';
import { groupEndPoints } from 'src/repositories/endPoints';

const Groups = () => {
  const [qrCodeData, setQrCodeData] = useState([]);
  const [selectedQrCode, setSelectedQrCode] = useState(null);
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);  
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedChannel, setSelectedChannel] = useState('');
  const { keycloak } = useKeycloak();

  const fetchQrCodeData = async () => {
    try {
      const response = await asaApiClient.get(groupEndPoints.getQrCodeList());
  
      if (Array.isArray(response.data)) {
        setQrCodeData(response.data);
      } else {
        console.error('API response is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching QR code data:', error);
    }
  };
  useEffect(() => {
    const fetchGroupsAndChannels = async () => {
      try {
        const response = await asaApiClient.get(groupEndPoints.getGroupList());
      
        if (Array.isArray(response)) {
          setGroups(response);
        } else {
          console.error('API response is not an array:', response);
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    fetchQrCodeData();
    fetchGroupsAndChannels();
  }, []);

  const handleQrClick = (qrCodeUrl) => {
    console.log('QR code URL:', qrCodeUrl);
    setSelectedQrCode(qrCodeUrl);
    setIsQrCodeModalOpen(true);  // Open the QR code modal when clicked
  };

  const closeQrCodeModal = () => {
    setSelectedQrCode(null);
    setIsQrCodeModalOpen(false);  // Close the QR code modal
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setSelectedGroup('');
    setSelectedChannel('');
  };

  const handleCreateQrCode = async () => {
    try {
      const response = await asaApiClient.post(groupEndPoints.generateQrCode(), {
        groupName: selectedGroup,
        channelName: selectedChannel,
        userId: keycloak.tokenParsed.sub
      });
      if (response.success) {
        await fetchQrCodeData(); 
        closeCreateModal();
        toast.success('QR code generation successful!');
      } else {
        console.error('Error generating QR code:', response.data);
        toast.error('QR code not generated.');
      }
    } catch (error) {
      console.error('Error generating QR code:', error);
      toast.error('QR code not generated.');
    }
  };
  return (
    <div style={{ padding: '20px' }}>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnHover draggable />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', padding: '10px', borderRadius: '5px' }}>
        <Typography variant="h4" sx={{ color: '#1a1a1a' }}>QRCodes</Typography>
        <Button
          onClick={openCreateModal}
          sx={{ backgroundColor: '#9fffac', color: '#000', padding: '10px 20px', borderRadius: '5px', '&:hover': { backgroundColor: '#7edc8b' } }}
        >
          + Create QRCode
        </Button>
      </div>

    <TableContainer component={Paper} sx={{ backgroundColor: '#252525' }}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ color: '#fff', borderRight: '1px solid rgba(255, 255, 255, 0.2)', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>SL No</TableCell>
          <TableCell sx={{ color: '#fff', borderRight: '1px solid rgba(255, 255, 255, 0.2)', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>Group Name</TableCell>
          <TableCell sx={{ color: '#fff', borderRight: '1px solid rgba(255, 255, 255, 0.2)', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>Channel Name</TableCell>
          <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>QR Code</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {qrCodeData.length > 0 && qrCodeData.map((item, index) => (
          <TableRow key={index}>
            <TableCell sx={{ color: '#fff', borderRight: '1px solid rgba(255, 255, 255, 0.2)', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>{index + 1}</TableCell>
            <TableCell sx={{ color: '#fff', borderRight: '1px solid rgba(255, 255, 255, 0.2)', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>{item.group_name}</TableCell>
            <TableCell sx={{ color: '#fff', borderRight: '1px solid rgba(255, 255, 255, 0.2)', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>{item.channel_name}</TableCell>
            <TableCell sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
              <Button
                onClick={() => handleQrClick(item.qrcode_s3_url)}
                sx={{ color: '#fff', background: 'none', '&:hover': { backgroundColor: '#444' } }}
              >
                <CIcon icon={cilQrCode} size="lg" />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>


      {isCreateModalOpen && (
        <CModal visible onClose={closeCreateModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <CModalBody
            sx={{
              backgroundColor: '#252525',
              padding: '30px',
              borderRadius: '8px',
              textAlign: 'center',
              width: '300px',
              maxWidth: '90%',
              position: 'relative'
            }}
          >
            <Button
              onClick={closeCreateModal}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '15px',
                fontSize: '1.5rem',
                color: '#fff',
                cursor: 'pointer',
                background: 'none',
                border: 'none'
              }}
            >
              &times;
            </Button>

            {/* Heading */}
            <Typography variant="h6" sx={{ color: '#fff', fontSize: '20px', marginBottom: '20px' }}>Create QR Code</Typography>

            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
            <InputLabel sx={{ color: '#ccc' }}>Select Group</InputLabel>
            <Select
              value={selectedGroup}
              onChange={(e) => { setSelectedGroup(e.target.value); setSelectedChannel(''); }}
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '8px',
                borderRadius: '5px',
                '& .MuiSelect-select': { padding: '5px' }
              }}
            >
              <MenuItem value="">Select a group</MenuItem>
              {groups.map((group, index) => (
                <MenuItem key={index} value={group.group_name}>{group.group_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel sx={{ color: '#ccc' }}>Select Channel</InputLabel>
            <Select
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '8px',
                borderRadius: '5px',
                '& .MuiSelect-select': { padding: '5px' }
              }}
            >
              <MenuItem value="">Select a channel</MenuItem>
              <MenuItem value="all">All</MenuItem>
              {selectedGroup && groups.find(group => group.group_name === selectedGroup)?.channel.map((channel, index) => (
                <MenuItem key={index} value={channel}>{channel}</MenuItem>
              ))}
            </Select>
          </FormControl>

          </CModalBody>

          <CModalFooter sx={{ backgroundColor: '#252525', padding: '10px' }}>
            <Button
              onClick={handleCreateQrCode}
              sx={{
                backgroundColor: '#9fffac',
                color: '#000',
                padding: '10px 15px',
                borderRadius: '4px',
                fontSize: '16px',
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                '&:hover': {
                  backgroundColor: '#9fffac'
                }
              }}
            >
              Create QR Code
            </Button>
          </CModalFooter>
        </CModal>
      )}
{/* QR Code Modal */}
{isQrCodeModalOpen && (
  <CModal visible={isQrCodeModalOpen} onClose={closeQrCodeModal}>
    <CModalHeader>
      <CModalTitle sx={{ color: '#fff' }}>QR Code</CModalTitle>
    </CModalHeader>
    <CModalBody sx={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h6" sx={{ color: '#fff', marginBottom: '10px', fontSize: '1.1rem' }}>
        Please scan this QR code to proceed with WhatsApp chat
      </Typography>
      <img src={selectedQrCode} alt="QR Code" style={{ width: '100%', height: '100%', objectFit: 'contain', marginBottom: '10px' }} />
    </CModalBody>
    <CModalFooter>
      <Button onClick={closeQrCodeModal} sx={{ backgroundColor: ' #9fffac', color: '#000' }}>
        Close
      </Button>
    </CModalFooter>
  </CModal>
)}
    </div>
  );
};

export default Groups;


import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
 url: "https://auth.apurva.ai/auth",
 realm: "impact-assessment",
 clientId: "ia-dashboard",
//  onLoad: 'login-required'
},);

export default keycloak;
